import React, { useContext, useEffect, useState } from "react";
import { GeneralSettingsContext } from "../contexts/GeneralSettingsContext";
import { getSettingValueByKey } from "../utils/settingsUtils";
import { FaCheck } from "react-icons/fa";
import { fetchPlan } from "../scripts/planList";
import { Link } from "react-router-dom";
import ReactHtmlParser from 'html-react-parser';
import Slider from "react-slick";
import { fetchBrand } from "../scripts/brandList";

const PricingAreaOne = () => {

  const settings = useContext(GeneralSettingsContext);
  const [plan, setPlan] = useState([]);
  const [brands, setBrands] = useState([]); // Initialize as an empty array
  
  const carouselSetting = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchPlan();
      setPlan(data);

      const dataBrand = await fetchBrand();
      setBrands(dataBrand);
    };

    fetchData();
  }, []);

 if (!settings) {
  return null;
  }


  return (
    <>
      {/* Pricing Area One start */}
      <div className='pricing-area bg-gray pd-top-120 pd-bottom-90'>
        <div className='container'>
          <div className='section-title text-center'  data-aos='fade-up' data-aos-delay='100' data-aos-duration='1500'>
            <h6 className='sub-title'>{getSettingValueByKey(settings,'plan_head')}</h6>
            <h2 className='title'>
            {getSettingValueByKey(settings,'plan_title')}
            </h2>
          </div>
          <div className='row'>
           {plan.map((data,index)=>(
            <div key={index} className='col-lg-4 col-md-6'  data-aos='fade-up' data-aos-delay='100' data-aos-duration='1500'>
              <div className={`single-pricing-inner style-3 ${index===1?'price-active':''}`}>
                <h2 className='mb-3'>
                  {data.price}
                </h2>
                <h5>{data.name}</h5>
                <div>
                  <ul>
                    <li>{ReactHtmlParser(`${data.detail}`)}</li>
                  </ul>
                </div>
                <Link to='/contact' className='btn btn-black border-radius border-radius-0 w-100'>Started</Link>
              </div>
            </div>

           ))}
          </div>
        </div>
      </div>
      {/* Pricing Area One End */}

      {/* Brand Section */}
      <div className="partner-14">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="partner__inner">
                  <div className="partner__slider owl-carousel">
                  <Slider {...carouselSetting}>
                    {brands && brands.map((data, index) => (
                      <div key={index} className="partner__slider-single">
                        <img src={data.image_url} alt="brand" />
                      </div>
                    ))}
                    </Slider>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default PricingAreaOne;
