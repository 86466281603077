import React, { useEffect, useState } from 'react';
import { FaEnvelope, FaFacebookF, FaPhone, FaSearch, FaUser } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import { getSettingValueByKey } from '../../../utils/settingsUtils';
import { fetchSocial } from '../../../scripts/socialList';

const NavBarOne = ({ webimage,menu,appUrl,settings }) => {
  const [active, setActive] = useState(false);
  const [searchShow, setSearchShow] = useState(false);
  const [socialBanner, setSocialBanner] = useState([]);
  const location = useLocation();

  const menuActive = () => {
    setActive(!active);
  };
  const searchActive = () => {
    setSearchShow(!searchShow);
  };

  const fetchSocialBannerData = async () => {
    const data = await fetchSocial();
    setSocialBanner(data);
  };
  
  useEffect(() => {
    fetchSocialBannerData();
  }, []);

  // Control sidebar navigation
  let items = document.querySelectorAll('.menu-item-has-children > a');
  for (let i in items) {
    if (items.hasOwnProperty(i)) {
      items[i].onclick = function () {
        this.parentElement
          .querySelector('.sub-menu')
          .classList.toggle('active');
        this.classList.toggle('open');
      };
    }
  }

  return (
    <>
      {/* search popup start*/}
      <div
        className={searchShow ? "td-search-popup active" : "td-search-popup "}
        id='td-search-popup'
      >
        <form action='/' className='search-form'>
          <div className='form-group'>
            <input
              type='text'
              className='form-control'
              placeholder='Search.....'
            />
          </div>
          <button type='submit' className='submit-btn'>
            <FaSearch />
          </button>
        </form>
      </div>
      {/* search popup end*/}
      <div
        onClick={searchActive}
        className={searchShow ? "body-overlay active" : "body-overlay"}
        id='body-overlay'
      ></div>


      {/* topbar start */}
      {location.pathname === '/' && (
      <div className="topbar d-none d-lg-block">
         <div className="container custom-container">
            <div className="row align-items-center">
               <div className="col-12 col-lg-7">
                  <div className="topbar__list-wrapper">
                     <ul className="topbar__list">
                        <li className="d-none d-xl-flex">Want to join? <Link to={'login'}>Sign In</Link>
                        </li>
                        <li><a href={`mailto:${getSettingValueByKey(settings,'contact_email')}`}><FaEnvelope></FaEnvelope> {getSettingValueByKey(settings,'contact_email')}</a>
                        </li>
                        <li><a href={`tel:${getSettingValueByKey(settings,'contact_number')}`}> <FaPhone></FaPhone>{getSettingValueByKey(settings,'contact_number')}</a>
                        </li>
                     </ul>
                  </div>
               </div>
               <div className="col-12 col-lg-5">
                  <div className="topbar__items justify-content-end">
                     <div className="social">
                     {socialBanner.map((data,index)=>(
                        <a key={data?.id} href={data.link}>
                          <FaFacebookF />
                          {/* <i className="fas fa-facebook"></i> */}
                        </a>
                      ))}
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      )}
      {/* topbar start */}

      {/* navbar start */}
      <nav className='navbar navbar-area navbar-area-2 navbar-expand-lg bg-white'>
        <div className='container nav-container custom-container'>
          <div className='responsive-mobile-menu'>
            <button
              onClick={menuActive}
              className={
                active
                  ? "menu toggle-btn d-block d-lg-none open"
                  : "menu toggle-btn d-block d-lg-none"
              }
              data-target='#itech_main_menu'
              aria-expanded='false'
              aria-label='Toggle navigation'
            >
              <span className='icon-left' />
              <span className='icon-right' />
            </button>
          </div>
          <div className='logo'>
            <Link to='/'>
              <img src={webimage.header_logo_image_url} alt='img' />
            </Link>
          </div>
          <div className='nav-right-part nav-right-part-mobile'>
            <span className='search-bar-btn' onClick={searchActive}>
              <FaSearch />
            </span>
          </div>
          <div
            className={
              active
                ? "collapse navbar-collapse sopen"
                : "collapse navbar-collapse"
            }
            id='itech_main_menu'
          >
            <ul className='navbar-nav menu-open text-lg-end'>
              
              {menu.map((data, index) => (
                <li key={index} className={data?.sub_menus.length > 0 ? 'menu-item-has-children' : ''}>
                  {data.sub_menus.length > 0 ? (
                    <Link to={`/menu/${data.id}/${data.slug}`}>{data?.name}</Link>
                  ) : (
                    <Link to={`/menu/${data.id}/${data.slug}`}>{data?.name}</Link>
                  )}
                  {data.sub_menus.length > 0 && (
                    <ul key={data.id} className='sub-menu'>
                      {data.sub_menus.map((subMenu, key) => (
                        <li key={key}>
                          <Link to={`/sub-menu/${subMenu.id}/${subMenu.slug}`}>{subMenu.name}</Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}

              <li>
                <Link to='/products'>Products</Link>
              </li>
              <li>
                <Link to='/contact'>Contact</Link>
              </li>
            </ul>
          </div>
          
          <div className='nav-right-part nav-right-part-desktop d-lg-inline-flex align-item-center'>
          <Link class="btn btn-border-base" to={'register'}>Join Us <FaUser></FaUser> </Link>
            <a className='navbar-phone' href={`tel:${getSettingValueByKey(settings,'contact_number')}`}>
              <span className='icon'>
                <img src={`${appUrl}/assets/img/icon/1.png`} alt='img' />
              </span>
              <span>Need help?</span>
              <h5>{getSettingValueByKey(settings,'contact_number')}</h5>
            </a>
          </div>
        </div>
      </nav>
      {/* navbar end */}
    </>
  );
};

export default NavBarOne;
