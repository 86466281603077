import React, { useContext, useEffect } from "react";
import { getSettingValueByKey } from "../utils/settingsUtils";
import BannerTemOne from "./templates/one/BannerTemOne";
import BannerTwo from "./templates/two/BannerTwo";
import BannerThree from "./templates/three/BannerThree";
import { GeneralSettingsContext } from "../contexts/GeneralSettingsContext";

const BannerOne = ({ webimage }) => {
  const settings = useContext(GeneralSettingsContext);

  useEffect(() => {
    if (settings) {
      const templateStatus = getSettingValueByKey(settings, "template_status") || "1"; 
      if (templateStatus === "1") {
        document.body.classList.add("home-14");
      } else {
        document.body.classList.remove("home-14");
      }
      // Clean up on unmount
      return () => {
        document.body.classList.remove("home-14");
      };
    }
  }, [settings]);

  const renderTemplate = () => {
    switch (getSettingValueByKey(settings, "template_status")) {
      case "1":
        return <BannerTemOne webimage={webimage} />;
      case "2":
        return <BannerTwo webimage={webimage} />;
      case "3":
        return <BannerThree webimage={webimage} />;
      default:
        return <BannerTemOne webimage={webimage} />;
    }
  };

  if (!settings) {
    return null;
  }

  return <>{renderTemplate()}</>;
};

export default BannerOne;
