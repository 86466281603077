import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getSettingValueByKey } from "../../../utils/settingsUtils";
import { GeneralSettingsContext } from "../../../contexts/GeneralSettingsContext";


const BannerTemOne = ({ webimage }) => {
  const settings = useContext(GeneralSettingsContext);
  
  if (!settings) {
    return null;
  }

  return (
    <React.Fragment>
      {/* Banner content */}
      <div className="banner-14 banner-area bg-relative banner-area-1 pd-bottom-100 bg-cover" style={{ backgroundImage: "url('./assets/img/home-14/banner/banner-bg.png')" }}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-8 col-xl-6">
              <div className="banner-inner">
                <h6 className="subtitle">{getSettingValueByKey(settings, "banner_head")}</h6>
                <h2 className="title">
                  <span>{getSettingValueByKey(settings, "banner_title")}</span>
                </h2>
                <p className="content">{getSettingValueByKey(settings, "banner_detail")}</p>
                <Link className="btn btn-base" to="/register">
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="banner-14-thumb">
          <img src={webimage.banner_image_url} alt="img" />
        </div>
      </div>

    </React.Fragment>
  );
};

export default BannerTemOne;
